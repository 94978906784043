var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs"},[_c('div',{class:['tabs__background', _vm.alternativeStyle ? 'tabs__background_alternative' : ''],style:({
      width: _vm.backgroundWidth + 'px',
      height: _vm.alternativeStyle ? 2 + 'px' : _vm.backgroundHeight - 2 + 'px',
      left: _vm.backgroundPosition + 'px'
    })}),_c('div',{class:['tabs__items', _vm.alternativeStyle ? 'tabs__items_alternative' : '']},_vm._l((_vm.tabs),function(tab,idx){return _c('div',{key:("tab_" + idx),ref:"tab",refInFor:true,class:[
        'tabs__item',
        idx === _vm.activeTab && _vm.alternativeStyle
          ? 'tabs__item_active-alternative'
          : idx === _vm.activeTab
          ? 'tabs__item_active'
          : '',
        _vm.alternativeStyle ? 'tabs__item_alternative' : ''
      ],on:{"click":function($event){return _vm.updateTab(idx, $event)}}},[_vm._v(" "+_vm._s(tab)+" ")])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }