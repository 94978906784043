<template>
  <div class="tabs">
    <div
      :class="['tabs__background', alternativeStyle ? 'tabs__background_alternative' : '']"
      :style="{
        width: backgroundWidth + 'px',
        height: alternativeStyle ? 2 + 'px' : backgroundHeight - 2 + 'px',
        left: backgroundPosition + 'px'
      }"
    ></div>
    <div :class="['tabs__items', alternativeStyle ? 'tabs__items_alternative' : '']">
      <div
        ref="tab"
        @click="updateTab(idx, $event)"
        v-for="(tab, idx) in tabs"
        :key="`tab_${idx}`"
        :class="[
          'tabs__item',
          idx === activeTab && alternativeStyle
            ? 'tabs__item_active-alternative'
            : idx === activeTab
            ? 'tabs__item_active'
            : '',
          alternativeStyle ? 'tabs__item_alternative' : ''
        ]"
      >
        {{ tab }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabsWidgetSecond",
  mixins: [],
  props: {
    tabs: {
      type: Array,
      default: () => {
        return [];
      }
    },
    label: String,
    alternativeStyle: {
      type: Boolean,
      default: false
    },
    unique: {
      type: String
    },
    defaultBlock: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    defaultIndex: {
      type: [Number, String],
      default: () => {
        return null;
      }
    }
  },
  components: {},
  data() {
    return {
      backgroundWidth: "",
      backgroundHeight: "",
      backgroundPosition: "",
      trueTabs: [],
      activeTab: 0,
      block: false
    };
  },
  mounted() {
    this.initializeTab();
    this.$root.$on(`tabs:block_${this.unique}`, (value) => {
      this.blockTabs(value);
    });
  },
  beforeDestroy() {
    this.$root.$off(`tabs:block_${this.unique}`);
    this.$root.$off(`tabs:unBlock_${this.unique}`);
  },
  methods: {
    updateTab(i, event) {
      if (this.block) {
        event.onclick.preventDefault();
      }
      this.activeTab = i;
      this.backgroundWidth = this.$refs.tab[i].clientWidth;
      this.backgroundHeight = this.$refs.tab[i].clientHeight;
      this.backgroundPosition = this.$refs.tab[i].offsetLeft;
      this.$emit("update:tab_item", this.trueTabs[this.activeTab]);
      this.$emit("update:tab_index", this.activeTab);
    },
    initializeTab() {
      if (this.defaultIndex) {
        this.updateTab(this.defaultIndex);
      }
      this.initializeTabsList();
      this.backgroundWidth = this.$refs.tab[this.activeTab].clientWidth;
      this.backgroundHeight = this.$refs.tab[this.activeTab].clientHeight;
      this.backgroundPosition = this.$refs.tab[this.activeTab].offsetLeft;
    },
    initializeTabsList() {
      this.trueTabs = [];
      this.tabs.forEach((item, idx) => {
        this.trueTabs.push({ id: idx, title: item });
      });
    },
    blockTabs(idx) {
      if (idx) {
        this.updateTab(idx);
        this.block = true;
      } else {
        this.block = false;
        this.updateTab(idx);
      }
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";

.tabs {
  position: relative;

  &__background {
    position: absolute;
    top: 3.5%;

    background-color: $color-interactive;
    transition: all 0.2s ease;
    border-radius: 4px;
    z-index: 2;

    &_alternative {
      bottom: 0;
      top: auto;
      background-color: $color-accent;
      border-radius: 0;
    }
  }

  &__items {
    width: max-content;
    display: flex;
    align-items: center;
    position: relative;
    background-color: $color-elements-background;
    border-radius: 4px;

    &_alternative {
      background-color: transparent;
    }
  }

  &__item {
    width: max-content;
    position: relative;
    padding: 6px 38px;
    height: 30px;
    z-index: 3;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-interactive;
    cursor: pointer;
    transition: all 0.3s ease;

    &:first-child {
      margin-left: 1px;
    }

    &:last-child {
      margin-right: 1px;
    }

    &_active {
      color: $color-background;
    }

    &_alternative {
      padding: 6px 32px 10px;

      color: $color-text-primary-2;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: $color-text-primary-1;
      }
    }

    &_active-alternative {
      color: $color-accent;

      &:hover {
        color: $color-accent;
      }
    }
  }
}
</style>
